import React from 'react';

const LandingPage = () => {
 return (
   <div className="min-h-screen bg-black">
     <div className="max-w-[700px] mx-auto px-4 pt-16">
       {/* Header */}
       <div className="text-center mb-16">
         <h1 className="text-6xl font-serif mb-24 text-white">Empiric Labs</h1>
         
         {/* Mathematical equation */}
         <div className="text-3xl font-serif mb-12 tracking-wide text-white" style={{ fontFamily: 'Times New Roman, serif' }}>
           ∀ε{'>'} 0, ∃δ{'>'} 0: |x-a|{'<'}δ ⟹ |f(x)-L|{'<'}ε
         </div>
         
         {/* Message and contact */}
         <div className="text-lg text-white">
         Building superintelligent agents to amplify human teams. Contact us at{' '}
           <a 
             href="mailto:team@empiric.dev" 
             className="text-white hover:text-gray-300 underline"
           >
             team@empiric.dev
           </a>
         </div>
       </div>
     </div>
   </div>
 );
};

export default LandingPage;